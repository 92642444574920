/* line 8, /home/boquistm/projects/d7/viu_theme/scss/print.scss */
.viu_title strong:after {
  content: " ";
}

/* line 14, /home/boquistm/projects/d7/viu_theme/scss/print.scss */
.viu-brand {
  width: 50px;
}

/* line 16, /home/boquistm/projects/d7/viu_theme/scss/print.scss */
.viu-brand a:after {
  content: "";
}

/* line 22, /home/boquistm/projects/d7/viu_theme/scss/print.scss */
#global-teaching-logo {
  display: none;
}

/* line 26, /home/boquistm/projects/d7/viu_theme/scss/print.scss */
.oa-flex-column {
  display: inline;
  height: auto;
}

/* line 30, /home/boquistm/projects/d7/viu_theme/scss/print.scss */
.container {
  width: auto;
}

/* line 33, /home/boquistm/projects/d7/viu_theme/scss/print.scss */
.row {
  margin: 0;
}

/* line 38, /home/boquistm/projects/d7/viu_theme/scss/print.scss */
.page-section, .page-section__content, .scrollmagic-pin-spacer {
  position: static !important;
  min-height: 0 !important;
  min-width: 0 !important;
  height: auto !important;
  padding: 0 !important;
  width: auto !important;
  transform: none !important;
  display: block !important;
  text-align: left !important;
  opacity: 1 !important;
  transform: none !important;
}

/* line 50, /home/boquistm/projects/d7/viu_theme/scss/print.scss */
.page-section .page-section__bg, .page-section__content .page-section__bg, .scrollmagic-pin-spacer .page-section__bg {
  display: none;
}

/* line 55, /home/boquistm/projects/d7/viu_theme/scss/print.scss */
.globalCTAs, .viuFooter__logo, #viuNavigation, .viuFooter__links-mobile, .viuFooter__links,
.viuFooter__contact__map, .viu-social, .viuFooter__secondary, .nav-viu, .viu-audience-nav,
#side-search-tab, #side-apply-tab {
  display: none !important;
}

/* line 62, /home/boquistm/projects/d7/viu_theme/scss/print.scss */
#footer {
  padding-top: 1em;
}

/* line 67, /home/boquistm/projects/d7/viu_theme/scss/print.scss */
.tab-content > .tab-pane {
  display: block;
  opacity: 1;
}

/* line 73, /home/boquistm/projects/d7/viu_theme/scss/print.scss */
.panel-collapse {
  display: block !important;
  height: auto !important;
}

/* line 79, /home/boquistm/projects/d7/viu_theme/scss/print.scss */
a {
  text-decoration: underline !important;
}

/* line 82, /home/boquistm/projects/d7/viu_theme/scss/print.scss */
a[href]:after {
  content: "";
}

/* line 86, /home/boquistm/projects/d7/viu_theme/scss/print.scss */
.ui-accordion-content {
  display: inline !important;
}
