// @file
// Print Styles
// --------------------------------------------------

// Basic fixes to print
.viu_title{
  strong{
    &:after{
      content: " ";
    }
  }
}

.viu-brand{
  width:50px;
  a:after{
    content:"";
  }
}

//Don't need the extra global teaching logo
#global-teaching-logo{
  display:none;
}

.oa-flex-column{
    display:inline;
    height:auto;
  }
.container{
  width:auto;
}
.row{
  margin:0;
}


.page-section, .page-section__content, .scrollmagic-pin-spacer{
  position: static !important;
  min-height: 0 !important;
  min-width: 0 !important;
  height:auto !important;
  padding: 0 !important;
  width:auto !important;
  transform: none !important;
  display: block !important;
  text-align: left !important;
  opacity: 1 !important;
  transform: none !important;
  .page-section__bg{
    display: none;
  }
}

.globalCTAs, .viuFooter__logo, #viuNavigation, .viuFooter__links-mobile, .viuFooter__links,
.viuFooter__contact__map, .viu-social, .viuFooter__secondary, .nav-viu, .viu-audience-nav,
#side-search-tab, #side-apply-tab{
  display: none !important;
}


#footer{
  padding-top: 1em;
}

// Show all tabs by default when printing
.tab-content > .tab-pane{
  display:block;
  opacity: 1;
}

// Show all accordion sections by default when printing
.panel-collapse{
  display:block !important;
  height:auto !important;
}

// Remove link URL's from display in print
a{
  text-decoration: underline !important;
}
a[href]:after {
  content: "";
}

.ui-accordion-content{
  display: inline !important;
}

